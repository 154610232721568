#Analytics {
  /*display: grid;
  height: calc(100vh - 70px);
  grid-template-rows: 1fr 2fr 2fr;*/
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
  background: #e9edf0;
  padding: 20px 40px 0px 40px;
  row-gap: 1.4em;
  overflow-y: auto;
}

.dateSelection {
  display: flex;
  flex-direction: row;
  row-gap: 1em;
  align-self: flex-end;
}

.react-datepicker__header {
  background-color: #e9edf0 !important;
  font-weight: 500 !important;
  color: #6d777a;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  color: #6d777a !important;
  font-weight: 500 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #047eab !important;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #047eab !important;
}

react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #047eab !important;
  color: #fff !important;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #047eab !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #047eab !important;
  color: #fff !important;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #047eab !important;
  color: #fff !important;
}

.react-datepicker {
  background-color: #f8f8f8 !important;
  border: 1px solid #aeaeae;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #047eab !important;
}


.react-datepicker__navigation-icon {
  color: #6d777a  !important;  
}

.react-datepicker__navigation-icon::before {
  border-color: #6d777a !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before {
  border-color: #6d777a !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent !important;
}

.dateSelectorButton {
  background: #e9edf0;
  color: #6d777a;
  font-weight: 500;
  height: 2em;
  padding-left: 1em;
  padding-right: 1em;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 0.5em;
  border: none;
  box-sizing: border-box;
}

.dateSelectorButton:hover {
  background: #047eab !important;
  color: white !important;
  font-weight: 600 !important;
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547 !important;
  cursor: pointer !important;
}

.basicText {
  font-size: 0.9em;
  margin-left: 1em;
  margin-right: 1em;
  align-self: flex-end;
  color: #6d777a;
}

.CashStats {
  display: flex;
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
  justify-content: space-around;
}

.MoneyBar {
  border-right: 0.5px solid #6d777a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 1em 3em;
  color: #6d777a;
  box-sizing: border-box;
  margin: 1em 0em;
}

.MoneyBar div:first-child {
  font-size: 1em;
  white-space: nowrap;
} 

.MoneyBarFinal {
  color: #047eab !important;
}

.MoneyBarFinal .figures {
  color: #047eab !important;
}

.figures {
  color: #6d777a;
  font-weight: 600;
  font-size: 1.5em;
}

.cashout button {
  border: none;
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 12.5px 35px;
  color: #149bd0;
  white-space: nowrap;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
}

.cashout button:hover {
  background: #047eab;
  color: white;
  font-weight: 600;
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547;
  cursor: pointer;
}

.cashout button:hover {
  background: #0f7ca7;
}

.cashout {
  align-content: center;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0px 50px;
}

.Graph {
  /*display: grid;
  grid-template-rows: auto 1fr;*/
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 1em;
  flex: 1 !important;
  flex-shrink: 1 !important;
  overflow-y: hidden;
}

.GraphH {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*margin-bottom: 1em;*/
}

.GraphH span {
  font-style: normal;
  font-weight: 600;
  color: #6d777a;
}

.GraphH div {
  display: flex;
  flex-direction: row;
  font-size: 0.8em;
  align-items: center;
  color: #6d777a;
}

.GraphH div div{
  background: #e9edf0;
  padding: 0.6em;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 0.5em;
  font-weight: 500;
  font-size: 1em;
  border: none;
  box-sizing: border-box;
  justify-content: center;
  align-content: center;
  margin-left: 1em;
  
}

.GraphH div div:hover{
  background: #047eab;
  color: white;
  font-weight: 600;
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547;
  cursor: pointer;
}

.selectedGranularity {
  background: #047eab !important;
  color: white !important;
  font-weight: 600 !important;
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547 !important;
}

.Gheadings {
  display: grid;
  grid-template-columns: 1fr 1.5fr 2fr 2fr 2fr 2fr 2fr 2fr;
  align-items: center;
  justify-items: center;
  color: #047eab;
  font-weight: 600;
  padding: 0px 20px 0px 20px;
  padding-bottom: 1em;
  box-shadow: 0px 12px 12px -12px #b8bcbf;

}

/* .Gheadings > div{
  border: 1px solid red;
} */


.Gheadings div:first-child {
  color: #6d777a;
  margin-right: -4em;
}


.tabs {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 10px;
}

.overview_section {
  display: flex;
  flex-direction: column;
}

.overview_section p {
  font-style: normal;
  font-weight: 600;
  color: #6d777a;
  margin-left: 0.8em;

  font-size: 0.9em;
}


.bar_graph_container {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px;
  padding-bottom: 10px;

  display: flex;
  flex-direction: column;
  width: 100%;

  /*row-gap: 10px;*/
}

.bar_graph_container > div:last-child {
  flex: 1;
}

.bar_graph {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 1fr;
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 0.5em;

  height: 150px;
}

.scrollable_graph {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: auto;
  white-space: nowrap;
  margin-left: 1em;
}

.scrollable_graph::-webkit-scrollbar-track {
  margin-left: 0em;
  padding-bottom: 5em;
}


.y_axis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  border-right: 1px solid #d8d8d8;
  padding-right: 15px;
  font-size: 0.8em;
  color: rgba(88, 91, 100, 1);
  margin-bottom: 2.8em;

  width: 3.6em;
}

.x_axis {
  border-top: 1px solid #d8d8d8;
  display: flex;
  /*grid-column: 2/3;*/
  font-size: 0.8em;
  color: rgba(88, 91, 100, 1);
  padding-bottom: 1em;
}

.x_axis > div {
  min-width: 6em;
  text-align: center;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center;
}

.bars {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: start;
  height: 100%;
  width: 100%;
}

.bars > div {
  height: 100%;
  width: 15px;
  background: linear-gradient(
      317.7deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 105.18%
    ),
    #e7ebf0;
  background-blend-mode: soft-light, normal;
  border: 0.5px solid rgba(255, 255, 255, 0.4);
  box-sizing: border-box;
  box-shadow: inset -2.5px -2.5px 5px #fafbff, inset 2.5px 2.5px 5px #a6abbd;
  border-radius: 2px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-left: 1.86em;
  margin-right: 1.86em;

  position: relative;
}

.bars > div > div {
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;

  background: linear-gradient(
      317.7deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(255, 255, 255, 0.4) 105.18%
    ),
    #047eab;
  background-blend-mode: soft-light, normal;
  box-sizing: border-box;
  box-shadow: inset -2.5px -2.5px 5px rgba(250, 251, 255, 0.1),
    inset 2.5px 2.5px 5px #366cbd;
  border-radius: 2px;
}

.no_data_message {
  font-size: 1.4em;
  color: #6d777a;
  display: flex;
  justify-content: center;
  padding-bottom: 1.4em;
}


.lds-dual-ring-3 {
  display: flex !important;
  justify-content: center !important;
  justify-items: center;
  align-items: center;
  width: 4em;
  height: 4em;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.lds-dual-ring-3:after{
  content: " ";
  display: flex;
  justify-content: center;
  width: 5em;
  height: 3.5em;
  margin-top: 1em;
  margin-bottom: 2em;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring-3 1.2s linear infinite;
}

.subText{
  font-size: 0.6em;
  margin-bottom: 1em;
}

.headingText{
  font-weight: 600;
}

#cashOutDialog {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  z-index: 10;
}


#cashOutDialogBody {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 34em;
  padding: 20px 20px;
  border-radius: 10px;

  background: #e9edf0;
  box-shadow: 0px 0px 8px rgb(255 255 255 / 78%), 0px 0px 8px rgb(0 0 0 / 15%);
}

.cashOutDialogSubBody {
  padding: 1em;
}

.cashOutDialogSubBody p {
  padding-left: 0em;
  padding-right: 0em;
}

.cashOutDialogSubHeading {
  padding-left: 0em;
  padding-right: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cashOutDialogInput {
  padding-left: 0.1em;
  padding-right: 0.1em;
}

.editIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.editIcon img {
  width: 1em;
  height: 1em;
}

.bankingInfo {
  margin-top: -1.8em;
  padding-bottom: 1em;
}

.normalBtn {
  font-size: 1em !important;
  color: #149bd0 !important;
}

.normalBtn:hover {
  background: #047eab !important;
  color: white !important;
  font-weight: 600 !important;
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547 !important;
  cursor: pointer !important;
}

.helperText {
  font-size: 0.8em;
  padding-bottom: 1em;
  margin-top: -1em;
  color: #4f4f4f;
}

@keyframes lds-dual-ring-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
