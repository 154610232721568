.audio_widget_item {
  width: 100%;
  padding: 8px;
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  display: grid;
  grid-template-columns: 35px 1fr 30px;
  gap: 10px;
  z-index: 99;
}

.audio_widget_item > div:first-child {
  height: 35px;
  width: 35px;
  border-radius: 35px;
  background: #e9edf0;
  box-shadow: -9.2795px -9.2795px 23.1988px rgba(255, 255, 255, 0.78),
    9.2795px 9.2795px 24.7453px rgba(0, 0, 0, 0.15);

  display: flex;
  align-items: center;
  justify-content: center;
}

.audio_widget_item > span {
  height: 35px;
  /* width: 35px; */
  border-radius: 35px;
  background: #e9edf0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.audio_widget_item > div:first-child > img {
  width: 50%;
}

.delete_icon {
  cursor: pointer;
}

.delete_icon img {
  width: 50%;
  cursor: pointer;
}
.audio_widget_item > img {
  width: 35%;
  margin: auto;

  cursor: pointer;
  user-select: none;
}

.rhap_controls-section {
  display: none !important;
}

.rhap_container {
  background-color: transparent;
  box-shadow: none;
  padding: 0;
}

.rhap_time {
  font-size: 0.75em;
}

.rhap_progress-indicator {
  width: 10px;
  height: 10px;
  top: -2px;
}

.file_name {
  font-size: 0.8em;
  margin-bottom: 5px;
  color: #5f636f;
  overflow-wrap: anywhere;
}

.file_name > span {
  color: #818996;
  font-weight: 600;
}

.audio_widget_item_pushed {
  width: 100%;
  padding: 8px;
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;

  display: grid;
  grid-template-columns: 35px 1fr 30px;
  gap: 10px;
}

.audio_widget_item_pushed > div:first-child {
  height: 35px;
  width: 35px;
  border-radius: 35px;
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);

  display: flex;
  align-items: center;
  justify-content: center;
}

.audio_widget_item_pushed > div:first-child > img {
  width: 50%;
}

.audio_widget_item_pushed > img {
  width: 35%;
  margin: auto;

  cursor: pointer;
  user-select: none;
}

.dialog {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  z-index: 10;
}

.dialog_bg {
  /* background-color: rgba(0, 0, 0, 0.6); */
  /* height: 50vh; */
}

.dialog_body {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 40vw;
  padding: 30px 60px;
  /* padding-bottom: 30px; */
  border-radius: 10px;

  background: #e9edf0;
  box-shadow: 0px 0px 8px rgb(255 255 255 / 78%), 0px 0px 8px rgb(0 0 0 / 15%);
}

.dialog_btn {
  margin-top: 20px;
  padding: 9px 16px;

  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  border: 0;
  color: #0378a6;

  font-family: "Montserrat";
  font-size: 1.1em;
  font-weight: 600;

  cursor: pointer;
  user-select: none;
}

.dialog_btn:hover {
  background: #c6c8ca;
}
