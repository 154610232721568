/*
Background Grey: #e9edf0

Primary Grey: #818996

Primary Blue-x: #62b2d1
Primary Blue: #149bd0
Primary Blue+x: #0e688c

Darker Grey: #4a4a4a

Darker Blue-x: #229bc7
Darker Blue: #047eab
Darker Blue+x: #013547

Lime Green: #99ff99

Red-x: #c9765b
Red: #cc3300
Red+x: #471403
*/

/* #tourBuilder {
  height: calc(100vh - 70px);

  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 20px;

  padding: 30px 20px 20px 20px;
}

#tourBuilder > div:last-child {
  height: calc(100vh - 110px);
  width: calc((100vw - 60px) * 0.67);

  display: grid;
  grid-template-rows: 1fr 120px;
  row-gap: 20px;
}

#tourBuilder > div:last-child > div,
#tourBuilder > div {
  border-radius: 10px;
}

#tourBuilderForm {
  height: calc(100vh - 110px);
  width: calc((100vw - 60px) * 0.33);

  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
}

#builderFormStartArea {
  padding: 15px 10% 10px 10%;
}

#builderFormInpArea {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 30px;
}

#builderFormInpArea .input_container {
  width: 80%;
  margin: auto;
}

#builderFormInpArea .input_container label {
  font-size: 0.8em;
  margin-bottom: 10px;
}

.form_heading {
  font-size: 1.2em;
  font-weight: bold;
  margin: 10px 0;
  color: #1f2944;
}

.form_ins {
  color: #5f636f;
  font-size: 0.85em;
  margin: 0px 0 30px 0;
}

.form_subheading {
  color: #818996;
  font-weight: 600;
  font-size: 0.9em;
}

#tourRouteData {
  position: relative;
}

.route_path {
  border-radius: 10px;
  height: calc(100% - 1em);
  width: 7px;
  position: absolute;
  top: 0;
  left: 5%;
  transform: translateX(-50%);
  background: #e9edf0;
  border: 1px solid rgba(220, 220, 220, 0.9);
  box-shadow: inset -6px -6px 15px rgb(255 255 255 / 90%),
    inset 6px 6px 16px rgb(0 0 0 / 30%);
}

.field_with_marker {
  position: relative;
}

.field_marker {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  position: absolute;
  top: calc(50% + 10px + 0.8em);
  left: 5%;
  transform: translate(-50%, -90%);
  background: #f4f4f4;
  box-shadow: inset 2px 2px 8px rgb(0 0 0 / 15%);
  border: 1px solid #f9f9f9;
}

.field_marker > span {
  width: 60%;
  height: 60%;
  border-radius: 100%;
  background: #01a0db;
  box-shadow: inset -2px -2px 4px rgb(255 255 255 / 21%),
    inset 2px 2px 3px rgb(0 0 0 / 15%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
}

.field_marker > img {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stops {
  margin: 20px 0;
  display: grid;
  row-gap: 15px;
}

.stop_object {
  height: 3.5em;
  width: 80%;
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  margin: auto;
}

.map {
  width: calc((100vw - 60px) * 0.67);
  height: 100%;
  position: relative;
  background-color: #202336;
}

#addARObjectsContainer {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
}

.image_upload_widget {
  width: 80%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: block;
  position: relative;

  margin: auto;
  margin-top: 15px;
}

.image_upload_widget > label {
  font-weight: 600;
  margin-top: 15px;
  font-size: 0.8em;
  margin-bottom: 10px;
  width: fit-content;
  color: #999eae;
}

.image_upload_widget > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;

  align-items: center;
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  padding: 15% 5% 7.5% 5%;
}

.image_upload_widget > div > div:nth-child(1),
.image_upload_widget > div > div:nth-child(2) {
  font-size: 0.9em;
  color: rgba(153, 158, 174, 1);
  font-weight: 600;
}

.upload_btn {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 30px;
  font-size: 0.9em;
}

.upload_btn:hover {
  background: #bdc1c4;
  cursor: pointer;
  user-select: none;
}

.uploaded_images_grid {
  width: 100%;
  margin-top: 30px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}

.uploaded_images_grid > div {
  width: 100%;
  height: 0;
  padding-top: 90%;
  position: relative;
  border-radius: 10px;
}

.uploaded_images_grid > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;

  position: absolute;
  top: 0;
  left: 0;
}

.uploaded_images_grid > div > div {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.uploaded_images_grid > div > div > img {
  width: 15%;
  cursor: pointer;
  user-select: none;

  position: absolute;
  right: 10%;
  top: 10%;
}

.uploaded_images_grid > div:hover > div {
  display: block;
}

#addARObjectsContainer {
  padding: 10px 40px;
  display: flex;
  flex-direction: column;
}

#addARObjectsContainer > div:first-child {
  font-weight: 600;
  font-size: 0.9em;
  color: rgba(144, 144, 144, 1);
}

#addARObjectsContainer > div:last-child {
  height: calc(100px - 0.9em);
  overflow-x: auto;
  white-space: nowrap;
  width: calc(((100vw - 60px) * 0.67) - 80px);
  overflow-y: hidden;

  display: flex;
  column-gap: 20px;
}

#addARObjectsContainer > div:last-child > img {
  height: 100%;
  cursor: pointer;
}

.ar_object {
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: center;
  justify-content: center;
}

.ar_object > img {
  height: 45px;
}

.ar_object > div {
  font-size: 0.9em;
  color: rgba(64, 71, 81, 1);
}

.marker {
  background-image: url("../../../assets/imgs/marker_icon.png");
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
} */

.toast {
  background: #e9edf0;
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 0.8rem;
  height: 10%;
}

.toast img {
  width: 1.2rem;
}

.toastProgressbar {
  background: #149bd0;
}

.Toastify__toast {
  min-height: 4em !important; 
}

.Toastify__toast-container--top-right {
  margin-top: -0.6em; 
  margin-right: 0em; 
}

#tourBuilder {
  height: calc(100vh - 70px);

  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 20px;

  padding: 20px 20px 20px 20px;
}

#tourBuilder > div:last-child {
  height: calc(100vh - 110px);
  width: calc((100vw - 60px) * 0.67);

  display: grid;
  grid-template-rows: 1fr 120px;
  row-gap: 20px;
}

#tourBuilder > div:last-child > div,
#tourBuilder > div {
  border-radius: 10px;
}

#tourBuilderForm {
  height: calc(100vh - 110px);
  width: calc((100vw - 60px) * 0.33);

  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);

  display: flex;
  flex-direction: column;
}

#builderFormStartArea {
  padding: 1em 1em 0.5em 2em;
  box-shadow: 0 4px 1px -2px lightgrey;
  z-index: 3;
}

#builderFormInpArea {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 30px;
  padding-top: 1em;
}

#builderFormInpArea .input_container {
  /*width: 80%;
  margin: auto;*/
  width: 85%;
  margin-left: 10%;
  margin-right: 5%;
}

#builderFormInpArea .input_container label {
  font-size: 0.8em;
  margin-bottom: 10px;
}

.form_heading {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0.4em 0.4em 0.1em 0;
  color: #1f2944;
}

.subtext_colored {
  font-size: 0.6em;
  font-weight: normal;
  color: #149bd0;
  margin-top: 0.3em;
}

.subtext_colored_2 {
  font-size: 1.1em;
  font-weight: medium;
  color: #149bd0;
  margin-top: -0.1em;
  margin-left: 0.6em;
}

.subtext_colored_3 {
  font-size: 1.1em;
  font-weight: medium;
  color: #149bd0;
  margin-top: 0.88em;
  margin-left: -0.4em;
  margin-right: -0.4em;
}

.subtext_red {
  font-size: 0.6em;
  font-weight: normal;
  color: #cc3300;
  margin-top: -0.3em;
}

.summary_text {
  display: flex;
  font-weight: 600;
  font-size: 0.8em;
  width: fit-content;
  color: #999eae;
}

.form_ins {
  color: #5f636f;
  font-size: 0.85em;
  margin: 0px 0 30px 0;
}

.form_subheading {
  margin-left: 10%;
  color: #818996;
  font-weight: 600;
  font-size: 0.9em;
}

#tourRouteData {
  position: relative;
}

.route_path {
  border-radius: 10px;
  height: calc(100% - 1em);
  width: 7px;
  position: absolute;
  top: 0;
  left: 5%;
  transform: translateX(-50%);
  background: #e9edf0;
  border: 1px solid rgba(220, 220, 220, 0.9);
  box-shadow: inset -6px -6px 15px rgb(255 255 255 / 90%),
    inset 6px 6px 16px rgb(0 0 0 / 30%);
}

.field_with_marker {
  position: relative;
}

.field_marker {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  position: absolute;
  top: calc(50% + 10px + 0.8em);
  left: 5%;
  transform: translate(-50%, -90%);
  background: #f4f4f4;
  box-shadow: inset 2px 2px 8px rgb(0 0 0 / 15%);
  border: 1px solid #f9f9f9;
}

.field_marker > span {
  width: 60%;
  height: 60%;
  border-radius: 100%;
  background: #01a0db;
  box-shadow: inset -2px -2px 4px rgb(255 255 255 / 21%),
    inset 2px 2px 3px rgb(0 0 0 / 15%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-55%, -50%);
}

.field_marker > img {
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stops {
  margin: 20px 0;
  display: grid;
  row-gap: 20px;
}

.map {
  width: calc((100vw - 60px) * 0.67);
  height: 100%;
  position: relative;
  background-color: #202336;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
}

@media (min-width: 2000px) {
  #tourBuilderForm {
    width: calc((100vw - 60px) * 0.25);
  }

  .map{
    width: calc((100vw - 60px) * 0.75);
  }

  #tourBuilder > div:last-child {
    width: calc((100vw - 60px) * 0.75);
  }
}

.bottom_right_bar > div{
  background: #e9edf0;
  border-radius: 0.6em;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
}


.ar_helper_text {
  height: 100%;
  font-size: 1.2em;
  font-weight: 600;
  color: #818996;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ar_helper_text p {
  padding-top: 1em;
}

/*
.bottom_right_bar div {
  background: #e9edf0;
  display: flex;
  align-items: center;
}
*/

#addARObjectsContainer {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
}

.image_upload_widget {
  width: 85%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: block;
  position: relative;

  margin-left: 10%;
  margin-right: 5%;
  margin-top: 15px;
}

.audio_upload_widget {
  width: 85%;
  margin-left: 10%;
  margin-right: 5%;
  margin-top: 15px;
}

.audio_upload_widget .upload_btn {
  width: fit-content;
}

.audio_upload_widget > div {
  margin-top: 10px;
}

.image_upload_widget > label,
.audio_upload_widget > label {
  font-weight: 600;
  margin-top: 15px;
  font-size: 0.8em;
  margin-bottom: 10px;
  width: fit-content;
  color: #999eae;
}

.image_upload_widget > div {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;

  align-items: center;
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  padding: 15% 5% 7.5% 5%;
}

.image_upload_widget > div > div:nth-child(1),
.image_upload_widget > div > div:nth-child(2) {
  font-size: 0.9em;
  color: rgba(153, 158, 174, 1);
  font-weight: 600;
}

.upload_btn {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 10px 30px;
  font-size: 0.9em;
}

.upload_btn:hover {
  background: #bdc1c4;
  cursor: pointer;
  user-select: none;
}

.uploaded_images_grid {
  width: 100%;
  margin-top: 30px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}

.uploaded_images_grid > div {
  width: 100%;
  height: 0;
  padding-top: 90%;
  position: relative;
  border-radius: 10px;
}

.uploaded_images_grid > div > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;

  position: absolute;
  top: 0;
  left: 0;
}

.uploaded_images_grid > div > div {
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.uploaded_images_grid > div > div > img {
  width: 15%;
  cursor: pointer;
  user-select: none;

  position: absolute;
  right: 10%;
  top: 10%;
}

.uploaded_images_grid > div:hover > div {
  display: block;
}

#addARObjectsContainer {
  padding: 0.9em 1.8em 0.3em 1.8em;
  display: flex;
  flex-direction: column;
}

#addARObjectsContainer > div:first-child {
  font-weight: 600;
  font-size: 0.9em;
  color: #4a4a4a;
}

#addARObjectsContainer > div:last-child {
  height: calc(100px - 0.9em);
  overflow-x: auto;
  white-space: nowrap;
  width: calc(((100vw - 60px) * 0.67) - 80px);
  overflow-y: hidden;

  display: flex;
  column-gap: 20px;
}

#addARObjectsContainer > div:last-child > img {
  height: 100%;
  cursor: pointer;
}

.ar_object {
  height: 90%;
  margin-top: 0.4em;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  align-items: center;
  justify-content: center;
}

.ar_object > img {
  height: 2.4em;
}

.ar_object > div {
  color: #818996;
  font-size: 0.7em;
  font-weight: 500;
}

.ar_object:hover {
  cursor: pointer;
}

.selected_ar_object {
  user-select: none;
  padding: 1em 0.8em;
  background-color: rgb(220, 220, 220);
  border-radius: 0.6em;
}

.marker,
.ar_marker {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  transform-origin: 0 25px;
}

.ar_marker {
  border-radius: unset;
}

.ar_marker > .ar_marker_img {
  height: 100%;
  width: 100%;
}

.ar_marker_locked {
  position: relative;
}

.ar_marker_locked > .ar_marker_img {
  opacity: 0.8 !important;
}

.ar_marker_lock_icon {
  display: none;
}

.ar_marker_locked > .ar_marker_lock_icon {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*
.edit_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
*/

.edit_heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

/*
.edit_btns {
  display: flex;
  gap: 20px;
  margin-right: -10%;
}*/

/*
.edit_btns {
  display: flex;
  gap: 20px;
}*/

.edit_btns {
  margin-top: 5px;
  margin-bottom: 10px;
  margin-left: 1em;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 1280px) {
  .edit_btns {
    flex-direction: column;
    gap: 10px;
    margin-bottom: 10px;
  }
}

.edit_btns button {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: none;
  padding: 1em 2em;
  color: #149bd0;
  font-weight: bold;

  white-space: nowrap;

  cursor: pointer;
  user-select: none;

  font-size: 0.7em;
}

.edit_btns .highlighted_btn {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 0.7em;
  color: #ffffff;
}

.edit_btns button:hover {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);*/
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547;
  background: #047eab;
  border-radius: 10px;
  color: #ffffff;
}

.edit_btns .highlighted_btn:hover {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
}

.edit_btns .highlighted_btn_red {
  /*background: linear-gradient(90.15deg, #cc3300 0.13%, #962601 99.87%);*/
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: #ffffff;
}

.edit_btns .highlighted_btn_red:hover {
  background: linear-gradient(90.15deg, #cc3300 0.13%, #962601 99.87%);
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: #ffffff;
}

.basic_btn {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 0.7em;
  color: #149bd0 !important;
}

.basic_btn:hover {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  background: #e9edf0;
  color: #f4f4f4 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
}

.expanded_btn {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 0.7em;
  color: #149bd0 !important;
  width: 100%;
  height: 4em;
  margin-top: 2em;
}

.expanded_btn:hover {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  background: #e9edf0;
  color: #f4f4f4 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
}

.publish_btn {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 0.7em;
  color: #149bd0 !important;
}

.publish_btn:hover {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  background: #e9edf0;
  color: #f4f4f4 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
}

.suspend_btn {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 0.7em;
  color: #cc3300 !important;
}

.suspend_btn:hover {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  background: #cc3300 !important;
  color: #f4f4f4 !important;
  box-shadow: inset -2px -2px 12px #b87965, inset 2px 2px 12px #471403 !important;
}

.inactive_btn {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  font-size: 0.7em;
  color: grey !important;
}

.inactive_btn:hover {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  background: #e9edf0 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78) ,
  inset 6px 6px 16px rgba(0, 0, 0, 0.15) !important;
}

.edit_btns_2 {
  display: flex;
  flex-wrap: space-between;
}

.save_btn {
  width: 85%;
  margin-top: 30px;
  margin-left: 10%;

  background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: #ffffff;
  border: none;
  padding: 10px 25px;

  cursor: pointer;
  user-select: none;

  font-size: 0.9em;
}

.delete_btn {
  background: linear-gradient(90.15deg, #cc3300 0.13%, #962601 99.87%);
  width: 10em;
}

.map_helper_txt {
  display: flex;
  flex-direction: row;
  gap: 1em;

  font-family: 'Montserrat';
  color: #4a4a4a;
  font-weight: bold;
  position: absolute;
  top: 1.2em;
  left: 1.2em;

  background: #e9edf0;
  border-radius: 16px;
  padding: 0px 1.9em 0px 1.3em;

  width: max-content;

  z-index: 2;

  border: 0px solid #333333;

  box-shadow: -2px 2px 8px lightgrey, 2px -2px 8px lightgrey;

  overflow: hidden;
  animation: slideInHorizontalText 0.5s ease forwards; /* You can adjust the duration and timing function */
  white-space: nowrap;
}

.map_search_btn {
  position: absolute;
  display: flex; 
  align-items: center; 

  top: 1em;
  right: 1em;

  background: #e9edf0;
  border-radius: 16px;
  padding: 8px;

  z-index: 2;

  cursor: pointer;

  border: 0px solid #333333;

  box-shadow: -2px 2px 8px lightgrey, 2px -2px 8px lightgrey;
}

.map_search_btn img {
  width: 30px;
}

.map_center_btn {
  position: absolute;
  display: flex; 
  align-items: center; 

  bottom: 9.6em;
  right: 1em;

  background: #e9edf0;
  border-radius: 0.6em;
  padding: 8px;

  z-index: 2;

  cursor: pointer;

  border: 0px solid #333333;

  box-shadow: -2px 2px 8px lightgrey, 2px -2px 8px lightgrey;
}

.map_center_btn img {
  width: 1.4em;
}

.zoom_in_btn {
  position: absolute;
  display: flex; 
  align-items: center; 

  bottom: 5.6em;
  right: 1em;

  background: #e9edf0;
  border-radius: 0.6em;
  padding: 8px;

  z-index: 3;

  cursor: pointer;

  border: 0px solid #333333;

  box-shadow: -2px 2px 8px lightgrey, 2px -2px 8px lightgrey;
}

.zoom_out_btn {
  position: absolute;
  display: flex; 
  align-items: center; 

  bottom: 2.4em;
  right: 1em;

  background: #e9edf0;
  border-radius: 0.6em;
  padding: 8px;

  z-index: 2;

  cursor: pointer;

  border: 0px solid #333333;

  box-shadow: -2px 2px 8px lightgrey, 2px -2px 8px lightgrey;
}


.slide_in_search {
  position: absolute;
  top: 0.9em;
  right: 1em;

  background: #e9edf0;
  border-radius: 16px;
  padding: 8px;

  z-index: 2;

  border: 0px solid #333333;
  box-shadow: -2px 2px 8px lightgrey, 2px -2px 8px lightgrey;

  overflow: hidden;
  animation: slideInHorizontalSearch 1s ease forwards; 
  white-space: nowrap;
}

.slide_out_search {
  position: absolute;
  top: 0.9em;
  right: 1em;

  height: 4.5em;

  background: #e9edf0;
  border-radius: 16px;
  padding: 8px;

  z-index: 2;
  box-shadow: -2px 2px 8px lightgrey, 2px -2px 8px lightgrey;

  overflow: hidden;
  animation: slideOutHorizontalSearch 0.32s ease-out; 
}

.slide_out_search::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3.5em;
  background-color: #e9edf0; 
  z-index: 3; 
  pointer-events: none; 
}

#locationSelectionDialog,
#arMarkerPictureDialog,
#arMarkerEditDialog,
#stopPictureDialog {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  z-index: 10;
}

#locationSelectionDialogBody,
#arMarkerPictureDialogBody,
#arMarkerEditDialogBody,
#stopPictureDialogBody {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  width: 30em;
  padding: 20px 20px;
  border-radius: 10px;

  background: #e9edf0;
  box-shadow: 0px 0px 8px rgb(255 255 255 / 78%), 0px 0px 8px rgb(0 0 0 / 15%);
}

#locationSelectionDialogBody .input_container label {
  font-size: 0.8em;
}

#locationSelectionDialogBody .input_container {
  width: 23em;
}

#locationSelectionDialogBody {
  padding: 20px 20px 40px 20px !important;
}

.close_dialog {
  display: flex;
  width: 100%;
  justify-content: end;
  cursor: pointer;
}

.close_dialog img {
  width: 30px;
  padding: 5px;
}

.dialog_header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.dialog_header img {
  width: 1.6em;
  padding: 5px;
}

.dialog_header h3 {
  color: #4a4a4a;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
}

#arMarkerEditDialogBody > div:nth-child(2),
#arMarkerPictureDialogBody > div:nth-child(2),
.img_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.img_container img {
  border-radius: 1em;
  height: 15em;
}


.img_container_2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2em;
}

.img_container_2 img {
  border-radius: 1em;
  height: 15em;
}

/*
#arMarkerEditDialogBody > div:nth-child(2) img,
#arMarkerPictureDialogBody > div:nth-child(2) img,
.img_container img {
  width: 18vw;
  height: 18vw;
  border-radius: 10px;
  margin-top: 20px;
  object-fit: cover;
  object-position: top;
}

#arMarkerPictureDialogBody > div:nth-child(2) button,
.img_container button {
  background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  border: none;
  padding: 12px 30px;
  margin-top: 10px;
  color: #ffffff;
  cursor: pointer;
}

#arMarkerPictureDialogBody > div:nth-child(3) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 20px;
}

#arMarkerEditDialogBody > div:nth-child(3) button,
#arMarkerPictureDialogBody > div:nth-child(3) button {
  flex: 1;
  padding: 15px 25px;
}
*/

.delete_button {
  background: #d65757 !important;
  border: none;
  color: #ffffff !important;
}

.cancel_button {
  background: #e9edf0 !important;
  border: none;
  color: #60696c !important;
}

.confirm_button {
  border: none;
  color: #ffffff !important;
}

.confirm_button_2 {
  border: none;
  color: #ffffff !important;
  margin-left: 0;
}

.replace_button {
  background: #e9edf0 !important;
  font-size: 0.8em;
  padding-top: 1.2em !important;
  padding-bottom: 1.2em !important;
  margin-top: 2.2em !important;
  border: none;
  color: #60696c !important;
  margin-left: 0;
}

.action_buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 100px;
  margin-top: 40px;
}

.action_buttons button {
  padding: 15px 25px;
}

.action_buttons > button:first-child {
  flex: 1;
}

.action_buttons > div {
  flex: 4;
  display: flex;
  align-items: center;
  column-gap: 20px;
}

.action_buttons > div > button:first-child {
  background: #e9edf0 !important;
  border: none;
  color: #60696c !important;
}

.action_buttons_2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action_buttons_2 button {
  width: 10em;
  padding: 15px 25px;
  font-weight: bold;
  margin-top: 2em;
}

.action_buttons_3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.action_buttons_3 button {
  width: 13em;
  padding: 15px 25px;
  font-weight: bold;
  margin-top: 2em;
}

.action_buttons_3 button:hover {
  background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%) !important; 
  color: #ffffff !important;
}

.action_buttons_3 .delete_button:hover {
  background: linear-gradient(90.15deg, #cc3300 0.13%, #962601 99.87%) !important;
  color: #ffffff !important;
}

.action_buttons_2 div {
  margin: 0px;
}


.selectError {
  border: 1px solid red;
  border-radius: 8px;
}
.noSelectError {
  border: none;
  border-radius: 8px;
}


.ar_helper_text {
  display: flex;
  flex-direction: row;
}

.ar_helper_text p{
  font-size: 0.8em;
  margin-top: 0.12em;
  margin-left: 0.5em;
}

.buttons_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}

.buttons_row button {
  height: 4em;
  margin-bottom: 0em !important;
  padding: auto !important;
}

.move_section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pull_down {
  margin-bottom: 0 !important;
}

/* Animations */
@keyframes slideInHorizontalText{
  0% {
    width: 1em;
  }
  99% {
    width: 30em;
  }
  100% {
    width: max-content;
  }
}

@keyframes slideOutHorizontalText{
  from {
    width: max-content;
  }
  to {
    width: 1em;
  }
}

@keyframes slideInHorizontalSearch{
  from {
    width: 1em;
  }
  to {
    width: 38em; 
  }
}

@keyframes slideOutHorizontalSearch{
  from {
    width: 38em; 
  }
  to {
    width: 5.5em;
  }
}
