.Toastify__toast {
  min-height: 4em !important; 
}

.Toastify__toast-container--top-right {
  margin-top: -0.6em; 
  margin-right: 0em; 
}

#dashboard {
  display: grid;
  grid-template-columns: 25em 1fr;
  background: #e5e5e5;
  height: calc(100vh - 70px);

  padding: 20px;
  column-gap: 20px;
}

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0.2em;
  box-sizing: border-box;
  height: calc(100vh - 6.8em);
  background: #047eab;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.sidebar > div {
  padding: 0.9em;
}

.sidebar > div:last-child {
  padding-bottom: 1.4em;
}

.ratingStars {
  margin-top: -0.5em;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.ratingStars div {
  align-self: flex-end;
  color: #f4f4f4;
  font-weight: 500;
  margin-left: 0.5em;
}

.profile {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.profDown {
  flex: 1;
  padding-left: 1em;
  padding-right: 1.5em;
  overflow-y: auto;
}

.profDown::-webkit-scrollbar-track{
  margin-top: 10px
}

.profUp {
  display: flex;
  flex-direction: column;
  align-items: center;

  row-gap: 20px;

  box-shadow: 0px 12px 12px -12px #01658a;
}

.logout_edit_btns {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.edit_button {
  display: flex;
  width: auto !important;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  cursor: pointer;
  user-select: none;
}

.edit_button img {
  width: 17%;
}

.edit_button span {
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}

.edit_button button {
  background: #047eab !important;
  font-weight: 500;
  box-shadow: -4px -4px 16px #229bc7, 4px 4px 16px #013547;
  border: none;
  border-radius: 10px;
  color: #ffffff !important;
  padding: 10px 20px;
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  cursor: pointer;
  user-select: none;
}

.edit_button button:hover {
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547;
}

.logout_button img {
  filter: none !important;
  cursor: pointer;
  user-select: none;
  filter: opacity(80%) !important;
}

/*
.logout_button button {
  background: linear-gradient(
    89.7deg,
    #0faed8 0.26%,
    #0378a6 103.31%
  ) !important;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15) !important;
  color: white !important;
  cursor: pointer;
  user-select: none;
}*/

.logout_button button {
  cursor: pointer;
  user-select: none;
  font-weight: 300;
  color: #f2f2f2 !important;
}

.logout_button button:hover {
  background: #cc3300 !important;
  box-shadow: inset -2px -2px 12px #b87965, inset 2px 2px 12px #471403;
  font-weight: 500;
}

.profimg {
  width: 33.75% !important;
  padding-top: 33.75% !important;
  display: flex;
  position: relative;
  margin-top: 0.2em;
}

.profimg > div {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.profimg img {
  width: 100%;
  height: 100%;
  margin: auto;
  object-fit: cover;
  object-position: center;
  border-radius: 0.8em;
}

.profName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  color: #ffffff;
  font-size: 1.5em;
}

.profCountry {
  color: #ffffff;
  font-size: 1em;
  margin-top: -1em;
}

.profV {
  color: #6be4ff;
  font-weight: 600;
}

/*
.divider {
  height: 0.8em;
  box-shadow: inset 0px 10px 12px -12px #01658a;
}*/

.info {
  /*display: grid;
  grid-template-columns: 1fr 1fr;*/
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  column-gap: 10px;
  color: #6be4ff;
  padding-top: 16px;
  margin-top: 0em;
}

.bio {
  padding-top: 10px;
  margin-top: 2em;
}

.bioH {
  color: #ffffff;
  font-weight: 600;
}

.bio p {
  color: #ffffff;
  margin-top: 5px;
  font-size: 0.9em;
}

.Tour {
  /*display: grid;
  grid-template-rows: auto auto 1fr;*/
  display: flex;
  flex-direction: column;
  height: calc(100vh - 110px);
}

.featured {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 0 0;
  padding-bottom: 1em;
  align-items: center;
  box-shadow: 0px 12px 12px -12px #b8bcbf;
}

.featured span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  color: #1f2944;
  font-size: 1.5em;
}

.featured button {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 10px;
  font-weight: 500;
  color: #4a4a4a;
  border: 0;
}

.featured button:hover {
  background: #047eab;
  color: white;
  font-weight: 600;
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547;
  cursor: pointer;
}

.tour_card {
  border-radius: 10px;
  position: relative;
  height: 0;

  /*padding-top: calc((100% - 60px) * 0.25);
  width: calc((100% - 60px) * 0.25);*/

  padding-top: 15em;
  width: 15em;
}

.tour_card > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  border-radius: 10px;
}

.TourGuides {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  /* height: calc(100vh - (1.5em + 110px ) );
    margin-bottom: 10px; */
}

.TourGuides::-webkit-scrollbar-track{
  margin-top: 1em;
}


::-webkit-scrollbar {
  width: 8px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #62aecc;
  border-radius: 12px;
}

.cards {
  display: flex;
  align-self: flex-start;
  flex: 1;
  flex-wrap: wrap;

  column-gap: 0em;
  row-gap: 0em;
}

.hoverE {
  background: rgba(0, 44, 61, 0.63);
  width: 100%;
  height: 100%;
  /* opacity: 0; */
  /* Note: backdrop-filter has minimal browser support */
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
}

.hoverE > div:last-child {
  opacity: 0;
}

.hoverE > div:first-child {
  letter-spacing: 0.015em;
  text-transform: capitalize;
  font-weight: 600;
  color: #ffffff;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 85%;
  position: absolute;

  justify-content: center;
}

.hoverE > div:first-child > div:first-child {
  font-size: 0.95em;
  margin-bottom: 0px;
}

.hoverE
  > div:first-child
  > div:not(.hoverE > div:first-child > div:first-child ) {
  font-size: 0.75em;
  margin-bottom: 3px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 0.2em;
  font-size: 0.75em !important;
}

.header > div:first-child > div:first-child{
  font-size: 1.4em !important;
}

.rating {
  text-align: center;
}

.rating > div {
  padding-top: 0.2em;
}

.tour_name {
  font-size: 1.2em !important;
}

.tour_card:hover > .hoverE > div:last-child {
  opacity: 1;
  transition: 1s;
}

.L_info,
.R_info {
  font-size: 0.9em;
  margin-bottom: 10px;
}

.R_info {
  color: white;
  margin-left: 1em;
  padding-right: 1em;
  font-weight: 500;
}

.L_info {
  font-weight: 400;
}

.hoverE > div:last-child {
  width: 90%;

  display: flex;
  align-items: center;

  position: absolute;
  bottom: 8%;
  left: 56%;
  transform: translateX(-50%);
}

.hoverE > div:last-child > div {
  display: flex;
  justify-content: flex-end;
  column-gap: 5px;
}

.card_icons {
  width: 18%;
  cursor: pointer;
}

.tick_icon {
  width: 13%;
}
.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.spinner:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: spinner 1.2s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tour_status_txt {
  color: #e3df7e;
  margin-bottom: 10px !important;
}


.distance_txt {
  margin-top: 14px !important
}

.published_txt {
  color: #7fdb7f !important;
}

.suspended_txt {
  color: #fc5a03 !important;
}

.lds-dual-ring-2 {
  display: inline-block;
  width: 5em;
  height: 5em;
  margin-left: 6.2em;
  margin-top: 6.8em;
}

.lds-dual-ring-2:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring-2 1.2s linear infinite;
}

@keyframes lds-dual-ring-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tour_card_encompasser {
  padding: 0.8em;
  margin: 1em 0.6em 0.6em 1em;
  border-radius: 1em;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.tour_card_encompasser:hover {
  box-shadow: inset -6px -6px 16px rgba(255, 255, 255, 0.98),
    inset 6px 6px 16px rgba(0, 0, 0, 0.45);
}


.dashboard_search_bar{
  width: 2em !important;
  margin-left: 1.4em;
  margin-right: 1.4em;
  margin-top: -10px;
}

.dashboard_search_bar > .input_container {
  width: 24em !important;
}

.dashboard_header {
  display: flex;
  flex-direction: row;
}

.dashboard_span {
  margin-left: 1.2em;
  margin-top: 0em;
  margin-bottom: -0.2em;
}

.new_tour_button {
  margin-top: -10px;
  margin-right: 0em;
}

.image_and_label {
  display: flex;
  flex-direction: row;
  gap: 0.6rem;
  margin-top: 0.4rem;
}

.image_and_label span{
  text-transform: lowercase;
}



@media screen and (max-width: 1280px) {
  .tour_card {
    width: 21.4em;
  }

  .hoverE > div:last-child {
    bottom: 10%;
    left: 80%;
  }

  .lds-dual-ring-2 {
    margin-left: 9em;
    margin-top: 6.2em;
  }

}

@media screen and (min-width: 1281px) and (max-width: 1440px) {
  .tour_card {
    width: 16.6em;
  }

  .hoverE > div:last-child {
    bottom: 10%;
    left: 62.8%;
  }

  .lds-dual-ring-2 {
    margin-left: 6.4em;
    margin-top: 6.4em;
  }

}

@media screen and (min-width: 1441px) and (max-width: 1536px) {
  .tour_card {
    width: 18.4em;
  }

  .hoverE > div:last-child {
    bottom: 10%;
    left: 71.8%;
  }

  .lds-dual-ring-2 {
    margin-left: 7.8em;
    margin-top: 6.4em;
  }
}