#authScreen {
  background: #e9edf0;
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  margin: auto auto;
  
  overflow: hidden;
}

.logo {
  display: flex;
  justify-content: center;
}

.logo img {
  width: 10em;
  height: 10em;
  margin-bottom: 1em;
}

.intro_text {
  display: flex;
  justify-content: center;
  font-family: Montserrat;
  font-weight: 500;
  color: #4a4a4a;
  margin-bottom: 1em;
}

.intro_text h3 {
  display: flex;
  justify-content: center;
  color: #149bd0;
  margin-top: 0.7em;
  margin-left: 0.3em;
  margin-right: 0.3em;
  margin-bottom: 0em;
}

.login_form_checkbox_and_link a {
  display: flex;
  justify-content: flex-end;
  color: #999eae;
  font-weight: 600;
}

.login_form_checkbox_and_link a:hover {
  display: flex;
  justify-content: flex-end;
  color: #047eab;
  font-weight: 600;
}

#loginRegisterTabs {
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3em;
  padding: 12px;
  padding-top: 6px;
  color: #999eae;
  transition: all 0.5s ease-in;
  border-bottom: 2px solid #ced3d6;
  box-shadow: 0px 4px 4px rgba(172, 172, 172, 0.25);
}

.country_input label {
  display: flex;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: -1em;
  font-size: 1.1em;
  width: fit-content;
  color: #999eae;
}

/*
.signUpOverFlow {
  display: flex;
}*/

.signUpOverFlow > :nth-child(2){
  height: calc(100% - 80px) !important;
  overflow-y: auto;
  align-items: center;
  padding: 1em;
}

.loginOverFlow{
  overflow-y: none;
}
#loginRegisterTabs > div {
  border-bottom: solid 3.5px transparent;
  cursor: pointer;
  user-select: none;
  padding-bottom: 2px;
}

#loginRegisterTabs > div:hover {
  color: #75c3e2;
  border-bottom: solid 3.5px #75c3e2;
}

.active_tab {
  color: #149bd0 !important;
  border-bottom: solid 3.5px #149bd0 !important;
}

#forms {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loginRegisterButton {
  height: 3em;
  width: 18em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #e1e5e8;
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 24px rgba(255, 255, 255, 0.78),
    6px 6px 24px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  color: #4a4a4a;
  cursor: pointer;
  border: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  text-align: center;
  letter-spacing: 0.015em;
  margin-top: 2em;
  margin-bottom: 1em;
}

#loginRegisterButton:hover {
  color: #ffffff;
  background: #047eab;
  box-shadow: inset -6px -6px 15px #229bc7,
    inset 6px 6px 16px #013547;
  border-radius: 16px;
}

.login_form_checkbox_and_link {
  width: 30em;
}

.Or {
  display: flex;
  justify-content: center;
  color: #999eae;
  padding: 20px;
  font-size: 1.2em;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
}

.auth_footer {
  display: flex;
  justify-content: center;
  gap: 5%;
}

.auth_footer button {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  border: 0;
  font-size: 2em;
  width: 2em;
}
