#TableData {
  display: grid;
  grid-template-columns: 0.5fr 1fr 1fr 2fr 2fr 2fr 2fr 2fr 2fr;
  align-items: center;
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  justify-items: center;
  padding: 0.4em;
}

#TableData span {
  color: #585b64;
}

#TableData > div:nth-child(2) > img {
  width: 3em;
  height: 3em;
  border-radius: 100%;
  object-fit: cover;
}

/*#TableData div {
  display: flex;
  flex: 1;
  border: 1px solid red;
}*/

.pullLeft {
  align-items: start;
  justify-self: self-start;
  justify-content: start;
}
