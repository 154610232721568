.Toastify__toast {
  min-height: 4em !important; 
}

.Toastify__toast-container--top-right {
  margin-top: -0.6em; 
  margin-right: 0em; 
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9edf0 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 16px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #62aecc !important;
  border-radius: 12px !important;
}

.Editing::-webkit-scrollbar-track{
  margin-top: 1em !important;
  margin-bottom: 6.2em !important;
}

#EditProfile {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 70px);
  /*grid-template-rows: auto 1fr;*/
  background: #e9edf0;
  padding: 1em 1em 0 1em;
  row-gap: 15px;
}

.EPData {
  display: flex;
  width: 100%;
  flex-direction: row;
  /*grid-template-columns: 1fr 3fr;*/
  background: #e9edf0;
  /*box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);*/
  border-radius: 10px;
  padding: 0 2em 0 2em;
}

.editProfH {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  color: #1f2944;
  padding-left: 15px;
}

.EPData > div:last-child {
  /*width: calc((100vw - 120px) * 0.67);
  height: calc(100vh - 185px - 1.2em);*/
  flex: 1;
  margin-left: 4em;
  margin-bottom: 0em;
  display: flex;
  flex-direction: column;
}

.EditProfBtn {
  display: flex;
  align-content: center;
  justify-content: space-between;
  box-shadow: 0px 12px 12px -12px #b8bcbf;
  padding-bottom: 15px;
  padding-top: 10px;
  padding-left: 15px;
  align-items: center;
  padding-right: 20px;
  margin-bottom: 0.1em;
  z-index: 10 !important;
}

.Uprofile,
.Uprofile > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Uprofile {
  justify-content: space-between;
  padding-bottom: 20px;
}

.Uprofile img {
  width: 12em;
  height: 12em;
  border-radius: 100%;
  margin-top: 20px;
  object-fit: cover;
}

.Uprofile button {
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: none;
  padding: 12px 30px;
  margin-top: 2em;
  color: #85888e;
  font-weight: 600;
  cursor: pointer;
}

.Uprofile button:hover {
  font-weight: 600;
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.profile_logout_btn {
  background: #e9edf0 !important;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15) !important;
  color: #85888e !important;

  font-weight: 600 !important;

  display: flex !important;
  justify-content: center !important;
  grid-gap: 10px !important;
  padding: 12px 20px !important;
  margin-bottom:  2em !important;
}

.profile_logout_btn:hover{
  /*background-color: #c1c1c5!important;*/
  background: #cc3300 !important;
  box-shadow: inset -2px -2px 12px #b87965, inset 2px 2px 12px #471403 !important;
  font-weight: 600 !important;
  color: #ffffff !important;
}

.profile_logout_btn:hover img{
  filter: brightness(90);
}


.profile_logout_btn img {
  width: 17%;
  height: auto;
  border-radius: 0 !important;
  margin-top: 0 !important;
}

.Editing {
  display: flex;
  flex-direction: column;
  /*margin: 20px 0;*/
  padding: 0.6em 1em 2em 1em;
  /*height: calc((100vh - 185px) - 1.2em - 100px);*/
  height: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
}
.Editing::-webkit-scrollbar {
  width: 6px;
}
.Editing::-webkit-scrollbar-track {
  border-radius: 10px;
}

.Editing > div:first-child {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 97%;
  column-gap: 20px;
}

@media screen and (min-width: 1900px) {
  .Editing > div:first-child {
    grid-template-columns: repeat(3, 1fr); /* For screens wider than 768px, 2 columns */
  }
}

@media screen and (min-width: 2200px) {
  .Editing > div:first-child {
    grid-template-columns: repeat(4, 1fr); /* For screens wider than 768px, 2 columns */
  }
}

.Editing .input_container {
  width: 97%;
}

.Editing .input_container label {
  font-size: 0.8em;
  margin-bottom: 10px;
}

.Editing > div:first-child .input_container:last-child {
  grid-column: 1/2;
}

.Editing > div:last-child {
  margin-top: 20px;
  padding-bottom: 4em;

  display: flex;
  flex-direction: column;
}

.basic_info_heading {
  margin-top: 0.2em;
  font-size: 1.2em;
  font-weight: 600;
}

.about_us_heading {
  font-size: 1.2em;
  font-weight: 600;
  border-bottom: 2px solid #ced3d6;
  box-shadow: 0px 4px 4px rgba(172, 172, 172, 0.25);
  color: rgba(88, 91, 100, 1);

  margin-bottom: 10px;
  padding-bottom: 10px;
}

.setBtns{
  margin-bottom: 0.2em;
}

.setBtns > :nth-child(1) {
  border-radius: 10px !important;
  color: #85888e !important;
  font-weight: 600 !important;
}

.setBtns > :nth-child(1):hover {
  font-weight: 600 !important;
  background: #e9edf0 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15) !important;
  cursor: pointer !important;
}

.setBtns > :nth-child(2) {
  background: #e9edf0 !important;
  border-radius: 10px !important;
  color: #149bd0 !important;
  font-weight: 700 !important;
}

.setBtns > :nth-child(2):hover {
  font-weight: 700 !important;
  background: #047eab !important;
  color: white !important;
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547 !important;
  cursor: pointer !important;
}
