#forgotPasswordScreen, #resetPasswordScreen {
  background: #e9edf0;
  height: 100vh;
}

.login_form_checkbox_and_link a {
  display: flex;
  justify-content: flex-end;
  color: #999eae;
  font-weight: 600;
}

#forgotPasswordHeader, #resetPasswordHeader {
  height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3em;
  color: #999eae;
  border-bottom: 2px solid #ced3d6;
  box-shadow: 0px 4px 4px rgba(172, 172, 172, 0.25);
}

#forgotPasswordHeader, #resetPasswordHeader > div {
  border-bottom: solid 3.5px transparent;
}

#forgotPasswordBody, #resetPasswordBody {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}

#back_btn {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  color: #999eae;
  cursor: pointer;
  margin-top: -20px;
  margin-bottom: 30px;
}

#back_btn:hover{
  color: #047eab;
}

#sendResetRequestButton, #resetPasswordButton {
  height: 3em;
  width: 18em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: #e1e5e8;
  /*background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);*/
  box-shadow: -6px -6px 24px rgba(255, 255, 255, 0.78),
    6px 6px 24px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  color: #4a4a4a;
  cursor: pointer;
  border: 0;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  text-align: center;
  letter-spacing: 0.015em;
  margin-top: 2em;
  margin-bottom: 1em;
}

#sendResetRequestButton:hover {
  color: #ffffff;
  background: #047eab;
  box-shadow: inset -6px -6px 15px #229bc7,
    inset 6px 6px 16px #013547;
  border-radius: 16px;
}

#resetPasswordButton:hover {
  color: #ffffff;
  background: #047eab;
  box-shadow: inset -6px -6px 15px #229bc7,
    inset 6px 6px 16px #013547;
  border-radius: 16px;
}

