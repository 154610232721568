.stop_object {
  min-height: 4.5em;
  width: 100%;
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 1em 0em 1em 1em;

  margin: auto;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.stop_object_active {
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15) !important;
}

.stop_object > div:first-child {
  display: grid;
  grid-template-columns: 3em 1fr 2em;
  align-items: top;
  justify-content: top;
  column-gap: 0.25em;
}

.stop_audio {
  display: grid;
  grid-template-columns: 3em 1fr;
  /* column-gap: 0.75em; */
  margin-top: 1em;
  margin-right: 1em;
  gap: 1.3em;
}

.stop_audio > div {
  display: flex;
  align-items: center;
}

.stop_audio > div > p {
  padding-left: 0.33em;
  font-size: 0.7em;
  color: #999eae;
  font-weight: bold;
}


.stop_audio_btn {
  display: flex;
  /*border: 1px solid black !important;*/
  font-size: small; 
  height: 3.6em;
  width: 3.6em;
  align-items: center;
  gap: 10px;
  margin-left: 0.3em;
  color: #0192c8;
  font-weight: 500;
  border-radius: 50%;
  text-decoration: underline;
  text-underline-offset: 2px;
  box-shadow:   -4px -4px 7px rgba(255, 255, 255, 0.6),
      6px 6px 10px rgba(0, 0, 0, 0.15)!important;
  border: 0px solid #ffffff;
}

.stop_audio_btn_active {
  display: flex;
  font-size: small;
  width: 95%;
  align-items: center;
  gap: 10px;
  color: #0192c8;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 22px;
}
.audio_mic_icon {
  height: 3.4em;
  width: 3.4em;
  border-radius: 50%;
  padding: 13px;

  background: #e9edf0;
  /*box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #ffffff;*/
}
.audio_mic_icon_active {
  box-shadow: inset 4px 4px 12px rgba(0, 0, 0, 0.15),
    inset -4px -4px 12px rgba(255, 255, 255, 0.5);
}

.audio_mic_icon > img {
  width: 100%;
  height: 120%;
  border-radius: 50%;

  object-fit: cover;
  object-position: top;
  cursor: pointer;
  user-select: none;
  z-index: 99;
}

.stop_audio .upload_btn {
  width: max-content;
}
.upload_btn {
  /* padding-top: 8px!important; */
  /* padding-bottom: 8px!important; */
  font-size: small !important;
  color: #999eae;
  font-weight: 500;
}

.stop_location_image {
  /*flex: 1;*/

  height: 2.92em;
  width: 2.92em;
  border-radius: 50%;

  margin-left: 0.16em;
  margin-right: 0.4rem;
  margin-top: 0.52em;

  /* transition: none !important; */

  background: #e9edf0;
  box-shadow:   -4px -4px 7px rgba(255, 255, 255, 0.6),
      6px 6px 10px rgba(0, 0, 0, 0.15)!important;
  border: 0px solid #ffffff;
}

.stop_location_image > img {
  width: 100%;
  height: 100%;
  /*border: 1px solid black !important;*/
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
  user-select: none;
  z-index: 99;
}

.stop_location_image > input {
  display: none;
}

.stop_images {
  display: grid;
  grid-template-columns: 3em 3em 1fr;
  /* column-gap: 0.5em; */
  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 1em;
  gap: 0.7em;
}

.stop_images_col {
  color: #999eae;
  font-weight: 600;
  font-size: 0.8em;
  text-transform: uppercase;
  font-family: "Montserrat";
  overflow-x: hidden; 
}

.stop_images_rows {
  display: flex;
  /* flex-wrap: wrap; */
  /* gap: 0.5rem; */
}

.stop_images > div {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0px;
}

.stop_images > div > p {
  padding-left: 0.33em;
  font-size: 0.7em;
  color: #999eae;
  font-weight: bold;
}


.stop_images > div:last-child {
  overflow-x: auto; 
  overflow-y: hidden; 
  margin-left: 0.5em;
  /* white-space: nowrap; */
  /* height: 100px; */
}

.stop_images_rows > div:last-child {
  /*overflow-x: auto; 
  white-space: nowrap;*/
  /* height: 100px; */
}

.stop_images > div:last-child > div {
  /* display: inline-block; */
  /*margin: 5px;*/
}

.stop_images .add_stop_image_btn {
  display: flex;
  align-self: center;

  /*border: 1px solid black !important;*/

  height: 2.94em;
  width: 2.94em;
  border-radius: 50%;

  margin-left: 0.82rem;
  margin-right: 0.4rem;

  cursor: pointer;

  /* transition: none !important; */

  background: #e9edf0;
  box-shadow:   -4px -4px 7px rgba(255, 255, 255, 0.6),
      6px 6px 10px rgba(0, 0, 0, 0.15)!important;
  border: 0px solid #ffffff;
}

.stop_images .add_stop_image_btn img{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.8em;
  width: 2.8em;
  padding-left: 0.1em;
}

.stop_images .add_stop_image_btn input{
  display: none;
}

.stop_details {
  width: 90%;
  display: flex;
  flex-direction: row;
  row-gap: 0.25em;
}

.stop_details > input {
  width: 100%;
  margin-right: 1em;
  outline: none;
  border: none;
  padding: 1em 1em;
  background: #e9edf0;
  box-sizing: border-box;
  font-weight: 500;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
}
.stopNumber {
  height: 2.5em;
  width: 2.5em;
  border-radius: 50%;
  padding: 2px;
  /* border: 1px solid #ffffff; */
  /* background-color: #d3d3d3; */
  display: flex;
  color: #4a4a4a;
  font-weight: 600;
  align-items: top;
  justify-content: top;

  /* box-shadow: inset -6px -6px 15px rgb(255 255 255 / 78%),
    inset 6px 6px 16px rgb(0 0 0 / 15%); */
  /* background: #e9edf0; */
  /* box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15); */
}
.stopNumber > span {
  font-family: sans-serif;
  height: 100%;
  width: 100%;
  background-color: #e9edf0;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stop_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.stop_header > div {
  white-space: nowrap;
  font-size: 0.65em;
  font-weight: 500;
  margin-left: 5px;
  color: #7f838e;
}

.stop_header > img {
  width: 12.5px;
  cursor: pointer;
  user-select: none;
  align-self: flex-start;
  padding-top: 0.33em;
}

.selected_stop > .stop_object {
  border: 3px solid #9797976e;
}

.input_fab {
  /* position: absolute !important; */
  /* top: 50%;
  right: 5%;
  transform: translateY(-5%); */
  user-select: none;
  cursor: pointer;
}

.input_fab > img {
  display: inline-block;
  width: 28px;
  user-select: none;
  cursor: pointer;
}
.ar_stop_images {
  display: grid;
  grid-template-columns: 3em 1fr;
  font-size: 0.8rem;
  gap: 0.65em;
  color: #999eae;
  width: 95%;
  font-family: "Montserrat";
  font-weight: 600;
  gap: 0.06em;
}

.ar_stop_images > :nth-child(2) {
  display: flex;
  overflow-x: auto;
  margin-left: 2.4em;
  gap: 0.8em;
}

.ar_stop_images > div {
  align-items: left;
}

.ar_stop_images > div > p {
  padding-left: 0.33em;
  font-size: 0.85em;
  color: #999eae;
  font-weight: bold;
  padding-right: 0.3em;
}


.ar_images {
  display: flex;

  padding-bottom: 0.6em;
  padding-left: 0.4em;
  margin-left: -0.2em;
  gap: 0.6rem;
}

.ar_images > div {
  max-width: 100%;
  margin-left: 0em;
  display: flex;
  align-items: left;
}

.ar_images > div > p {
  margin-left: 0.2em;
  font-size: 1em;
  color: #999eae;
  font-weight: normal;
}

.arMarkerImages {
  /*border: 1px solid black !important;*/
  height: 3.66em;
  width: 3.66em;
  border-radius: 50%;
  position: relative;
  background: #e9edf0;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
}

.arMarkerImages > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
  user-select: none;
}

/* .arMarkerImages:hover >  */
.arMarkerType {
  width: 2.2em;
  height: 2.2em;
  border-radius: 50%;
  background: #e9edf0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 2px;
  border: 1px solid #ffffff;
  position: absolute;
  bottom: -5px;
  right: -2px;
}

.arMarkerType img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
  user-select: none;
  z-index: 99 !important;
}

.ar_marker_edit_icon {
  display: none;
  padding: 15% !important;
  border-radius: 0 !important;
}

.arMarkerImages:hover .ar_marker_type_icon {
  display: none;
}

.arMarkerImages:hover .ar_marker_edit_icon {
  display: block;
}

.arMarkerImages > input {
  display: none;
}

.arMarker_active {
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  padding: 2px;
  border: solid 1px #62aecc;
}
.editARMarker {
  width: 1.7em;
  height: 1.7em;
  border-radius: 50%;
  background: #e9edf0;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 3px;
  border: 1px solid #ffffff;
  position: absolute;
  top: -6px;
  right: -8px;
}
.editARMarker img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  cursor: pointer;
  user-select: none;
  z-index: 90;
}
