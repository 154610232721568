#navbar {
  display: grid;
  grid-template-columns: auto 1fr;
  align-content: center;
  height: 70px;
  background: #f8f8f8;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    4px 4px 14px rgba(0, 0, 0, 0.15);
  align-items: center;
}
.nav {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.navlogo {
  display: flex; 
  align-items: center; 
  margin-left: 1.5em;
}

.nav a {
  color: #6d777a;
  text-decoration: none;
  font-weight: 600;
}
.nav a:hover {
  text-decoration: 3px underline;
  text-underline-offset: 5px;
  color: #149bd0;
}

.nav_active_tab {
  text-decoration: 3px underline !important;
  text-underline-offset: 5px !important;
  color: #149bd0 !important;
}
