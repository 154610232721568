.Toastify__toast {
  min-height: 4em !important; 
}

.Toastify__toast-container--top-right {
  margin-top: -0.6em; 
  margin-right: 0em; 
}

::-webkit-scrollbar {
  width: 8px !important;
  height: 6px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e9edf0 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 16px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #62aecc !important;
  border-radius: 12px !important;
}

.settingValues::-webkit-scrollbar-track{
  margin-top: 1.4em !important;
  margin-bottom: 1.4em !important;
}


#setting {
  /*display: grid;
  grid-template-columns: 1fr 3fr;*/
  display: flex;
  flex-direction: row;
  background: #e9edf0;
  height: calc(100vh - 70px);
  padding: 0 1em 0 4em;
  column-gap: 3em;
}

.settingbars {
  height: calc(100vh - 120px);
  width: 40em;

  display: flex;
  flex-direction: column;
  justify-content: top;
  gap: 20px;

  padding-top: 2.4em;
}

@media screen and (min-width: 1900px) {
  .settingbars {
    width: 20em;
  }
}

.settingbars button {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 25px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1em;
  line-height: 34px;
  color: #6d777a;
  border: solid 3px transparent;

  cursor: pointer;
  user-select: none;
}

.settingbars button:hover {
  /*color: #0077a3;*/
  border: none;
  font-weight: 600 !important;
  background: #e9edf0 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15) !important;
}

.selectedTab {
  border: none;
  font-weight: 600 !important;
  background: #e9edf0 !important;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15) !important;
  color: #047eab !important;
}

.settingData {
  height: calc(100vh - 70px - 2.1em);
  align-self: center;
  width: 100%;
  flex: 1;
  display: flex;
  margin-top: 2.1em;
  flex-direction: column;
  justify-content: end;
  /*grid-template-rows: auto 1fr;
  row-gap: 10px;*/
}

.settingH {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  color: #1f2944;
}

.settinginfo {
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  /*height: calc(100vh - 120px);*/
  background: #e9edf0;
  /*box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);*/
  border-radius: 5px;
  padding: 0 2em 0 2em;
  box-sizing: border-box;
}

.ChangeP span {
  color: #585b64;
  font-weight: 600;
}

.settingBtn {
  display: flex;
  width: calc(100vw *0.60);
  justify-content: space-between;
  /*border-bottom: 2px solid #ced3d6;
  box-shadow: 0px 4px 2px rgba(172, 172, 172, 0.25);*/
  box-shadow: 0px 12px 12px -12px #b8bcbf;
  margin-bottom: 0.1em;
  padding: 0 1em 1em 1em;
  align-items: center;
}

.setBtns {
  display: flex;
  gap: 20px;
  margin-bottom: 0.4em;
}

.setBtns button {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: none;
  padding: 10px 35px;
  color: #85888e;

  cursor: pointer;
  user-select: none;
}

.setBtns button:first-child{
  display: none;
}

.setBtns button:last-child,
.setBtns button:hover {
  background: linear-gradient(89.7deg, #0faed8 0.26%, #0378a6 103.31%);
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  color: #ffffff;
}


.settingValues {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  /*padding-left: calc(100vw * 0.14) !important;*/
  padding-top: 1.4em;
  height: calc(100vh - 120px - 50px);
  justify-content: flex-start !important;
  align-items: center;
  overflow-y: auto;
}

.settingValues span {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 0.8em;
  line-height: 17px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #999eae;
}

.CPASS {
  padding-bottom: 15px;
}

.NPASS {
  padding-bottom: 15px;
}

.CNPASS {
  padding-bottom: 15px;
}

#Cpass {
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  padding: 10px;
  width: 40%;
}

#Npass {
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  padding: 10px;
  width: 40%;
}

#CNpass {
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  padding: 10px;
  width: 40%;
}

.settingValues .input_container {
  width: 30em;
}

.settingValues .input_container label {
  font-size: 0.8em;
  margin-bottom: 10px;

  padding-top: 10px !important;
}

.dropdown_label {
  display: flex;
  font-weight: 600;
  margin-top: 15px;
  font-size: 0.8em;
  margin-bottom: 10px;
  width: fit-content;
  color: #999eae;
}

.settingValues .checkbox_container .checkmark:after {
  left: 7px;
  top: 0px;
  border-color: #e9edf0;
}
