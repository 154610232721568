.dialog {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;

  z-index: 10;
}

.dialog_bg {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
}

.dialog_body {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 40vw;
  padding: 30px 0px;
  /* padding-bottom: 30px; */
  border-radius: 10px;

  background: #e9edf0;
  box-shadow: 0px 0px 8px rgb(255 255 255 / 78%), 0px 0px 8px rgb(0 0 0 / 15%);
}

.dialog_body > div:not(.dialog_body > .lds-dual-ring) {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialog_body img {
  width: 80px;
}

.dialog_body p {
  margin-top: 30px;
  color: #818996;
  text-align: center;

  font-size: 1.05em;
  font-family: "Montserrat";
  font-weight: 600;
}

.dialog_btn {
  margin-top: 20px;
  padding: 12px 16px;

  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  border: 0;
  color: #0378a6;

  font-family: "Montserrat";
  font-size: 1.1em;
  font-weight: 600;

  cursor: pointer;
  user-select: none;
}

.dialog_btn:hover {
  background: #c6c8ca;
}

/* Loader Start */

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Loader End */
