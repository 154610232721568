.input_container {
  display: flex;
  flex-direction: row;
  width: 30em;
  justify-content: center;
  align-items: center;
  display: block;
  position: relative;
}

.input_container label {
  display: flex;
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 5px;
  font-size: 1.1em;
  width: fit-content;
  color: #999eae;
}

.input_container input[type="text"],
.input_container input[type="email"],
.input_container input[type="password"],
.input_container textarea {
  background-color: transparent;
  border-radius: 16px;
  border: 0;
  width: 100%;
  height: 3.5em;
  padding: 0 20px;
  outline: none;
  resize: none;
}

.input_container textarea {
  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
}


.input_container > div {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  background: #e9edf0;
  box-shadow: inset -6px -6px 15px rgba(255, 255, 255, 0.78),
    inset 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  padding: 0 10px;
}
.not_editable >div{
  box-shadow:   -6px -6px 15px rgba(255, 255, 255, 0.78),
      6px 6px 16px rgba(0, 0, 0, 0.15)!important;
  border-radius: 10px!important;

}

.input_container div > span {
  font-weight: 600;
  color: #6e7280;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
}

.input_container textarea {
  height: 14em;
  padding: 20px;
  line-height: 1.5em;
}

.input_container input:-webkit-autofill,
.input_container input:-webkit-autofill:hover,
.input_container input:-webkit-autofill:focus,
.input_container input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

/* The container */
.checkbox_container {
  padding-left: 4em;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  color: #4a4a4a !important;

  font-size: 0.9em !important;
  font-weight: 400 !important;
  margin-top: 20px !important;
  margin-bottom: 0 !important;
  margin-left: 0px;
  height: 30px;
  display: flex !important;
  align-items: center;
  /* padding-bottom: 20px; */
}

/* Hide the browser's default checkbox */
.checkbox_container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2em;
  width: 2em;
  background-color: #ffffff;
  border: #999eae;
  border-radius: 5px;

  margin-left: 1em;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
  
}

/* Show the checkmark when checked */
/* .checkbox_container input:checked ~ .checkmark:after {
  display: block;
} */

.checkbox_container input ~ .checkmark  {
  background-color: #e9edf0;
  box-shadow: inset -2px -2px 4px #f4f4f4, inset 2px 2px 4px #b0b1b5;

  /* display: block; */
}

.checkbox_container input:checked ~ .checkmark  {
  background-color: #047eab;
  box-shadow: inset -0.2px -0.2px 4px #229bc7, inset 0.2px 0.2px 4px #013547;
  /* display: block; */
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
  left: 11px;
  top: 4px;
  width: 7px;
  height: 16px;
  border: solid #5248fd;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.input_tab input::-webkit-calendar-picker-indicator {
  display: none;
  opacity: 0;
}

.cors input{
  padding: 12px!important;
}
.cors input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cors input[type=number] {
  -moz-appearance: textfield;
}


.input_tab input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 2px 30px 2px 2px;
  border: none;
  background-color: transparent;
}

.not_editable .input_tab input {
  color: #85888e;
  font-weight: bold;
}

.not_editable .input_tab input[name="endLocation"] {
  color: #50C878;
}

.input_tab span{
  font-size: small;
}

.input_tab input::-ms-expand {
  display: none;
}

.input_fab {
  /* position: absolute !important; */
  /* top: 50%;
  right: 5%;
  transform: translateY(-5%); */
  user-select: none;
  cursor: pointer;
}

.input_fab > img {
  display: inline-block;
  width: 28px;
  user-select: none;
  cursor: pointer;
}

.input_button {
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
  border: none;
  padding: 10px 25px !important;
  font-weight: bold;

  white-space: nowrap;

  cursor: pointer;
  user-select: none;

  font-size: 0.9em;
}

.input_button {
  display: flex !important;
  background: #e9edf0;
  box-shadow: -6px -6px 15px rgba(255, 255, 255, 0.78),
    6px 6px 16px rgba(0, 0, 0, 0.15) !important;
  border-radius: 10px !important;
  border: none;
  padding: 25px 25px !important;
  font-weight: bold;

  margin-top: 0px;
  margin-bottom: 40px;

  white-space: nowrap;

  cursor: pointer;
  user-select: none;

  font-size: 0.9em;
}

.input_button:hover {
  box-shadow: inset -2px -2px 16px #229bc7, inset 4px 4px 16px #013547 !important;
  background: #047eab !important;
}

.input_button:hover span{
  color: #fafafa !important;
}

.input_button:hover svg{
  color: #000000 !important;
  fill: #000000 !important;
}

.input_button:hover #plus_svg{
  fill: #fafafa !important;
}

.input_button div {
  display: flex !important;
  flex: 1 !important;
  align-items: center !important;
  flex-direction: row !important; 
  justify-content: center !important;
}

.input_button div img {
  flex: 0 0 auto; /* Don't grow or shrink, stay fixed size */
  margin-right: 10px;
}




.input_button div span{
  flex: 1 !important;
  text-align: center !important;
  font-weight: 700 !important;
  color: #6e7280;
  position: relative !important;
  top: 50%;
  transform: translateY(0%) !important;
  margin-left: 0px !important;
  text-align: center !important;
  color: #149bd0 !important;

}
